import React from "react"
import { Link } from "gatsby"
import Layout from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/layout"

export default () => {
  return (
    <Layout>
      <h2>
        Not Found
      </h2>
      <seciton>
        お探しの記事は見つかりませんでした。
        <p><Link to={"/"}>トップページに戻る</Link></p>
      </seciton>
    </Layout>
  )
}
